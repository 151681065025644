export class AnagraficaModelloImport {
	constructor() {
		this.itemID = null;
		this.descrizione = null;
		this.schemaFile = null;
	}
	itemID: number;
	descrizione: string;
	schemaFile: string;
}
export class AnagraficaModelloImportEdit extends AnagraficaModelloImport {
	/**
	 *
	 */
	constructor() {
		super();
		this.schemaFile_uploadFile = null;

	}
	schemaFile_uploadFile: File;
}