import gridComponentBase from "@/components/grid/gridComponentBase.vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { TextValueItem } from "@/models/TextValueItem";
import api from "@/services/api";
import { StatiItem } from "@/models/amministrazione/stati/statiItem";
import permessi from "@/config/permessi";
import { AnagraficaModelloImport, AnagraficaModelloImportEdit } from "@/models/anafrafica/AnagraficaModelloImport";
import apiFile from "@/services/apiFile";

const _ENDPONT = api.endpoint.AMMINISTRAZIONE.LISTA_TEMPLATE_PRATICHE_MULTIPLE;
@Component({})
export default class ListaTemplate extends gridComponentBase {
	get PERMESSI(): string[] {
		return [permessi.BK.Amministrazione.PRATICHE_MULTIPLE];
	}
	editItem: AnagraficaModelloImportEdit = new AnagraficaModelloImportEdit();
	showEditModal: boolean = false;
	editSubTitle: string = "";
	editTitle: string = "";
	kendop: any = kendo;

	showOnlyActive: boolean = false;

	get columns(): Array<any> {
		return [
			{ field: "itemID", title: "ID", filterable: false, hidden: false, headerCell: "myHeaderCellTemplate", editHide: true, width: 50 },
			{ field: "descrizione", title: this.$i18n.t("generico.descrizione").toString(), headerCell: "myHeaderCellTemplate" },

			{
				field: "schemaFile",
				title: this.$i18n.t("generico.template").toString(),
				width: 500,
				cell: "downloadSchemaCellTemplate",
				headerCell: "myHeaderCellTemplate",
				type: "upload-file",
				export: true,
				acceptFile: ".xlsx,.xls"
			},

			{ field: "_actions", title: " ", width: 50 + (this.canDelete ? 50 : 0), cell: "defaultCellTemplate", type: "actions_list", filterable: false, headerCell: "myHeaderCellTemplate" }
		];
	}

	sort: any = [{ field: "descrizione", dir: "asc" }];

	created() {
		var self = this;
		this.take = 60;
		this.pageable.pageSize = 60;
		this.pageSize = 60;
		if (this.group) {
			this.group.map(group => (group.aggregates = this.aggregates));
		}
		this.group = this.group;

		Promise.all([])
			.then(responses => {
				self.getData();
			})
			.catch(err => {
				console.log(err);
			});
	}

	getData() {
		this.getGridData(`${_ENDPONT}`);
	}

	onDelete(item: any) {
		var self = this;
		var message = self.$createElement("div", { domProps: { innerHTML: `<h5>${item.itemID}</h5>${self.$i18n.t("messaggio.confermaCancellazione")}` } }); // h('div', { domProps: { innerHTML: 'Title from <i>HTML<i> string' } })
		var title = this.$i18n.t("titolo.elimina", [this.$i18n.t("generico.stati")]).toString();
		self.deleteGridRow(`${_ENDPONT}/mapping`, item.itemID, title, [message]);
	}

	addNewRow() {
		let newItem = new AnagraficaModelloImportEdit();

		this.onEdit(newItem);
	}

	onEdit(item: any) {
		var self = this;
		// api.get

		self.editItem = Object.assign(new AnagraficaModelloImportEdit(), item);
		self.editSubTitle = "";
		self.editTitle = `${item.itemID ? item.itemID : ""} - ${item.descrizione ? item.descrizione : ""}`;
		self.showEditModal = true;
	}

	// onSaveEdit() {
	// 	var self = this;
	// 	var title = this.$i18n.t("msg.salvataggioRiuscito").toString();
	// 	var message = this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.template") }).toString();
	// 	self.setGridRow<StatiItem>(_ENDPONT, self.editItem, title, message, self.isNewItem).then(res => {
	// 		self.showEditModal = false;
	// 	});
	// }
	onSaveEdit() {
		var self = this;

		api.setTemplatePraticheMultiple(self.editItem, self.isNewItem)
			.then(res => {
				this.$bvToast.toast(this.$i18n.tc("msg.salvato_iCorrettamente", 1, { obj: this.$i18n.t("generico.template") }).toString(), {
					variant: "success",
					title: this.$i18n.t("msg.salvataggioRiuscito").toString(),
					solid: true
				});
				self.getData();
				//if (self.isNewItem) {

				self.editItem = res.data;
				// }
				// else {

				// 	self.showEditModal = false;

				// }
			})
			.catch(err => {
				console.log(err);
			});
	}

	get isNewItem(): boolean {
		return (this.editItem || {}).itemID == null || (this.editItem || {}).itemID <= 0;
	}

	downloadGenericModel() {
		var self = this;
		var nomeFile = "ModelloGenericoPraticheMultiple";

		apiFile
			.downloadFileModelloPraticheMultiple("Excel", nomeFile)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/xlsx" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile + ".xls";
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	downloadModelPartecipantsSameRow() {
		var self = this;
		var nomeFile = "ModelloPraticheMultiple_PartecipantiUnicaRiga";

		apiFile
			.downloadFileModelloPraticheMultiple("Excel", nomeFile)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/xlsx" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = nomeFile + ".xls";
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}

	downloadFileSchema(item: AnagraficaModelloImportEdit) {
		var self = this;
		var fileName = item.schemaFile.replace(/\.[^/.]+$/, "");

		apiFile
			.downloadFileSchemaPraticheMultiple("Excel", fileName)
			.then(response => {
				const blob = new Blob([response.data], { type: "application/xlsx" });
				const link = document.createElement("a");
				link.href = URL.createObjectURL(blob);
				link.download = item.descrizione + ".xlsx";
				link.click();
				URL.revokeObjectURL(link.href);
			})
			.catch(err => {
				console.log(err);
				var msg = this.$i18n.t("errore.fileDaScaricareNonTrovato").toString();
				self.$bvToast.toast(msg, {
					variant: "danger",
					title: self.$i18n.t("msg.erroreGenerico").toString(),
					solid: true
				});
			});
	}
}
